import { Catalog } from '../components/types'
import { getBaseCatalog } from './util'

export const catalog: Catalog = {
  name: 'Plomería',
  keywords: ['Fontanero', 'Plomero', 'Domicilio', 'Bogotá'],
  categories: [
    {
      name: 'Plomería',
      services: [
        {
          name: 'Instalación de grifería',
          description:
            'Se realiza la instalación y mantenimiento en baños, orinales, sanitarios, lavamanos, lavaplatos y grifería de las marcas Corona, Mancesa etc, y en accesorios de las siguientes marcas como: Grival, Gricol y Elfo, entre otras marcas.',
          image: 'instalacionGriferia',
          lowPrice: '20000',
          highPrice: '90000',
        },
        {
          name: 'Destapes de cañerías',
          description:
            'Se realiza el desatasco de tuberías de aguas negras usando sondas eléctricas para ingresar al conducto y eliminar obstrucciones que impiden el flujo normal de los desagües como; sifones, sanitarios, drenajes, lavamanos etc, sin romper paredes ni pisos.',
          image: 'destapesCanerias',
          lowPrice: '40000',
          highPrice: '170000',
        },
        {
          name: 'Instalación de duchas eléctricas',
          description:
            'Es una resistencia eléctrica incorporada al cabezal de la ducha para calentar el agua que fluye, realizamos la reparación e instalación de toda clase de ducha eléctricas los repuestos son originales con garantía.',
          image: 'instalacionDuchasElectricas',
          lowPrice: '40000',
          highPrice: '180000',
        },
        {
          name: 'Instalación de calentadores eléctricos y a gas',
          description:
            'Se hace el montaje de calentadores en marcas como: Haceb, Bosch, LG, Centrales, Mabe, Challenger y de cualquier otra marca.',
          image: 'instalacionCalentadores',
          lowPrice: '180000',
          highPrice: '450000',
        },
        {
          name: 'Reparación de calentadores eléctricos y a gas',
          description:
            'Realizamos arreglos y mantenimiento de calentadores de diferentes marcas como: Haceb, Bosch, LG, Centrales, Mabe, Challenger y de cualquier otra marca.',
          image: 'reparacionCalentadores',
        },
        {
          name: 'Instalación y reparación de estufas eléctricas o a gas',
          description:
            'Trabajamos con todas las marcas reconocidas de electrodomésticos, brindándole a nuestros clientes la garantía de que nuestros técnicos cuentan con la capacidad, el conocimiento y los repuestos específicos para cada una de ellas.',
          image: 'reparacionEstufas',
        },
        {
          name: 'Sistema de geofono digital',
          description:
            'Utilizamos este sistema para detectar fugas en tuberías en grandes superficies, el geófono registra el movimiento del suelo generado por una fuente energética, en nuestro caso, el agua, y convierte este movimiento en una señal eléctrica que nos permite indicar la posición de la fuga a través de ondas de sonido. Como en piscinas, baños, cocinas, patios a la intemperie, etc.',
          image: 'geofonoDigital',
        },
      ],
    },
  ],
}

export const getCatalog = (imageSources: Record<string, any>) => {
  return getBaseCatalog(catalog, imageSources, '/plomeria/')
}
