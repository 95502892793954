import * as React from 'react'
import { Catalog, Service, Category } from './types'
import { Product } from './products'

export const CatalogComponent: React.FC<{
  catalog: Catalog
  light?: boolean
}> = ({ catalog, light }) => {
  return (
    <div
      role="list"
      className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9'}
    >
      {catalog.categories.map((category, index) => (
        <ProductsList
          key={index}
          category={category}
          products={category.services}
          light={light}
        />
      ))}
    </div>
  )
}

export const ProductsList: React.FC<{
  category: Category
  products: Service[]
  light?: boolean
  render?: (service: Service) => React.ReactNode
}> = ({ products, category, light, render }) => {
  return (
    <>
      {products.map((product, index) => (
        <Product
          key={`${category.name}:${index}`}
          card={light}
          image={product.image}
          name={product.name}
          description={product.description}
          minPrice={product.lowPrice}
          maxPrice={product.highPrice}
          url={product.url}
          category={category.name}
          children={render?.(product)}
        />
      ))}
    </>
  )
}
