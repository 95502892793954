import { copFormat } from '../lib/numbers'
import * as React from 'react'
import { Link } from 'gatsby'

export const ProductsWrapper: React.FC<
  React.HTMLProps<HTMLDivElement>
> = props => {
  return <>{props.children}</>
}

export const Product: React.FC<{
  image: React.ReactNode
  category: string
  name: string
  description: string
  minPrice?: string
  maxPrice?: string
  card?: boolean
  url?: string
}> = ({
  url,
  image,
  name,
  category,
  description,
  minPrice,
  maxPrice,
  card,
  children,
}) => {
  return (
    <div
      role="listitem"
      className={
        'border border-gray-200 p-2.5 bg-white rounded-md text-left shadow-lg'
      }
    >
      <div
        className={'relative rounded overflow-hidden mb-4 text-center'}
        style={{ height: '200px' }}
      >
        <div className={'absolute z-10 top-3 left-3'}>
          <Tag>{category}</Tag>
        </div>
        <SafeURL url={url}>{image}</SafeURL>
      </div>
      <SafeURL url={url}>
        <h3 className={'text-center font-bold text-md mb-2'}>{name}</h3>
      </SafeURL>
      {!!minPrice && !!maxPrice && (
        <p className={'text-center mb-4 text-md'}>
          Desde <Price value={minPrice} /> hasta: <Price value={maxPrice} />
        </p>
      )}
      <p className={'text-left'}>{description}</p>
      <div>{children}</div>
    </div>
  )
}

const Price: React.FC<{ value: string }> = ({ value }) => {
  const valueWithFormat = React.useMemo(() => copFormat(value), [value])

  return <span className={'font-semibold'}>{valueWithFormat}</span>
}

const SafeURL: React.FC<{ url?: string }> = ({ url, children }) => {
  return (
    <>
      {url && <Link to={url}>{children}</Link>}
      {!url && children}
    </>
  )
}

const Tag: React.FC = ({ children }) => {
  return (
    <div className={'bg-gray-200 p-1 text-black rounded text-sm'}>
      {children}
    </div>
  )
}
