import * as React from 'react'

export const GrayBox: React.FC<React.HTMLProps<HTMLDivElement>> = props => {
  return (
    <div
      className={'pt-12 border-t border-b border-gray-200 bg-gray-50'}
      {...props}
    />
  )
}

export const DefaultContainer: React.FC<
  React.HTMLProps<HTMLDivElement>
> = props => <div className={'container mx-auto 2xl:px-48 px-4'} {...props} />
