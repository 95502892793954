import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Catalog } from '../components/types'

export const getBaseCatalog = (
  catalog: Catalog,
  imageSources?: Record<string, any>,
  url?: string,
) => {
  return {
    ...catalog,
    categories: catalog.categories.map(category => {
      category.url = url

      if (imageSources) {
        category.services.forEach(service => {
          if (service.image && typeof service.image === 'string') {
            if (!imageSources[service.image]) {
              return
            }

            const fluid =
              imageSources[service.image].childImageSharp.gatsbyImageData
            service.imageURL = fluid.src
            service.image = (
              <GatsbyImage
                image={fluid}
                alt={service.name}
                style={{ height: '100%' }}
                loading={'lazy'}
              />
            )
          }
        })
      }

      return category
    }),
  }
}
