import { getBaseCatalog } from './util'
import { Catalog } from '../components/types'
import config from '../components/config'

export const catalog: Catalog = {
  name: 'Cerrajería',
  keywords: ['Cerrajero', 'Bogotá', 'Domicilio'],
  categories: [
    {
      name: 'Cerrajería automotriz',
      services: [
        {
          name: 'Programación de llaves con chip',
          description:
            'Realizamos programación de llaves con chip y duplicado de llaves con chip de todo tipo de marca.',
          image: 'llavesConChip',
          lowPrice: '100000',
          highPrice: '500000',
          url: '/cerrajeria/programacion-de-llaves-con-chip/',
        },
        {
          name: 'Duplicación de llaves',
          description:
            'Elaboramos la copia de las llaves de su vehículo en todas las distintas marcas con equipos de última generación.',
          image: 'duplicacionDeLlaves',
          lowPrice: '30000',
          highPrice: '200000',
          url: '/cerrajeria/apertura-de-vehiculos/',
        },
        {
          name: 'Apertura de vehículos blindados',
          description:
            'Debido a que los vehículos blindados cuentan con características como cristales multicapa con policarbonato o protección anti esquirlas, es necesario utilizar herramientas diseñadas especialmente para la apertura.',
          image: 'aperturaDeVehiculos',
          lowPrice: '40000',
          highPrice: '120000',
          url: '/cerrajeria/apertura-de-vehiculos/',
        },
        {
          id: 'aperturaVehiculosAltaGama',
          name: 'Apertura de vehículos de alta gama',
          description:
            'Servicio especializado en vehículos de alta tecnología y sistemas de seguridad complejos, trabajamos con las marcas Audi, Mazda, BMW, Mercedes Benz, Toyota, Volkswagen etc.',
          image: 'aperturaVehiculosAltaGama',
          lowPrice: '40000',
          highPrice: '120000',
          url: '/cerrajeria/apertura-de-vehiculos/',
        },
        {
          name: 'Apertura de vehículos de media y baja gama',
          description:
            'Contamos con manuales de todas las marcas. Realizamos la apertura por ganzuado con herramientas especializadas sin desmontar y sin afectar su vehículo',
          image: 'aperturaVehiculosAltaBaja',
          lowPrice: '40000',
          highPrice: '120000',
          url: '/cerrajeria/apertura-de-vehiculos/',
        },
      ],
    },
    {
      name: 'Cajas fuertes',
      services: [
        {
          name: 'Apertura de cajas fuertes',
          description:
            'Se realiza la apertura de todas las marcas de cajas fuertes independientemente de su antigüedad, modificaciones, cambio de cerraduras o reprogramación anteriormente realizadas.',
          image: 'aperturaDeCajasFuertes',
        },
        {
          name: 'Mantenimiento de cajas fuertes',
          description:
            'Realizamos el mantenimiento y la restauración de cajas de seguridad, cambio de claves, guardas o de cerraduras.',
          image: 'mantenimientoCajasFuertes',
          lowPrice: '80000',
          highPrice: '250000',
        },
        {
          name: 'Instalaciones de cajas fuertes',
          description:
            'Una buena ubicación para la caja de seguridad es esencial tanto para su exacta fijación como para su difícil localización y apertura no autorizada.',
          image: 'instalacionesCajasFuertes',
        },
      ],
    },
    {
      name: 'Puertas de seguridad',
      services: [
        {
          name: 'Apertura de puertas de seguridad',
          description:
            'Trabajamos con marcas reconocidas en el mercado como Mul-t-lock, Rav Bariach, Secureme, Iseo fiam, Wondf, Kale, Yale entre otras.',
          image: 'aperturaPuertasSeguridad',
        },
        {
          name: 'Cambio de guardas',
          description:
            'Se cambian las guardas para evitar cambiar toda la cerradura. Debido a que la guarda solo es una parte de la cerradura y está directamente conectada con la llave. El cambio de la llave es necesario.',
          image: 'cambioDeGuardas',
          lowPrice: '20000',
          highPrice: '150000',
          url: '/cerrajeria/cambio-de-guardas-o-cerraduras/',
        },
        {
          name: 'Cambio de cerraduras o chapas sencillas',
          description:
            'Realizamos la instalación de todo tipo de cerraduras para su hogar o negocio, bien sean cerraduras para puertas, portones, persianas, rejas metálicas, o para cualquier tipo de puerta interior (metal o madera).',
          image: 'cambioDeChapasSencillas',
          lowPrice: '40000',
          highPrice: '120000',
        },
        {
          name: 'Cambio de cerraduras o chapas de seguridad',
          description:
            'Contamos con un amplio portafolio de cerraduras que cumplen con las normas y niveles de seguridad requeridos como Tesa, Segurex, Ezcurra, Justor, etc.',
          image: 'cambioDeCerraduras',
        },
        {
          name: 'Instalación de puertas de seguridad',
          description:
            'Se hace la instalación de puertas de seguridad de marcas como Holztek y referencias como Intemperie Cambridge, Luxor, Alejandría, Viena, Amberes, Montecarlo, etc.',
          image: 'instalacionDePuertasDeSeguridad',
        },
        {
          name: 'Brazos hidráulicos',
          description:
            'Realizamos la instalación de brazos hidráulicos pues es un mecanismo para controlar la velocidad de cierre de una puerta, también llamado amortiguador.',
          image: 'brazosHidraulicos',
          lowPrice: '150000',
          highPrice: '350000',
        },
        {
          name: 'Ojos mágicos para puertas',
          description:
            'Pequeño agujero en la parte superior de la puerta que permite ver hacia el exterior, se realiza el montaje para garantizar la seguridad de su domicilio y de su familia.',
          image: 'ojosMagicos',
          lowPrice: '30000',
          highPrice: '90000',
        },
      ],
    },
  ],
}

export const getCatalog = (imageSources: Record<string, any>) => {
  return getBaseCatalog(catalog, imageSources, '/cerrajeria/')
}

export const getAskedQuestions = () => {
  return [
    {
      question: '¿Me entregan una factura por el servicio de cerrajería?',
      answer:
        'Sí, en caso de que usted solicite una factura nuestro cerrajero o técnico especializado le entregará una con el detalle del servicio teniendo en cuenta los productos y servicios ofrecidos.',
    },
    {
      question: '¿Dónde se encuentran ubicados?',
      answer:
        'Contamos con varios puntos de atención en toda Bogotá, siempre le informaremos el tiempo que nos tomará llegar a su ubicación, así que en caso de que nuestros técnicos se encuentren ocupados o lejos de su ubicación podrá rechazar el servicio.',
    },
    {
      question:
        '¿Cuánto tiempo tengo que esperar por el servicio de cerrajería?',
      answer:
        'El tiempo que nos toma llegar a atender un servicio está entre 15 y 30 minutos, teniendo en cuenta factores como el tráfico, la hora de solicitud del servicio o el estado de nuestros técnicos. En caso de que no necesite el servicio de inmediato podemos agendar una cita a la hora y fecha que usted desee. Garantizamos el cumplimiento y el tiempo del servicio.',
    },
    {
      question: '¿En qué horario puedo solicitar un servicio de cerrajería?',
      answer:
        'Puede solicitar un servicio las 24 horas del día, nuestros técnicos cuentan con turnos de trabajo que nos permite cubrir las 24 horas del día, en caso de que cuente con una emergencia puede comunicarse a nuestro número de celular o escribirnos un mensaje por alguno de los canales de comunicación.',
    },
    {
      question:
        '¿El domicilio está incluido en el costo del servicio de cerrajería?',
      answer:
        'En caso de que usted solicite uno de nuestros servicios el precio de domicilio está incluido en el valor del mismo. Si usted únicamente necesita una revisión técnica le informaremos el precio al momento de solicitar el servicio.',
    },
    {
      question:
        'Si solicito un servicio de cerrajería ¿Qué métodos de pago aceptan?',
      answer:
        'Este es el listado de los métodos de pago disponibles: ' +
        config.main.paymentAccepted.join(', ') +
        '.',
    },
  ]
}
