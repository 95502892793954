import { Catalog } from '../components/types'
import { getBaseCatalog } from './util'

export const catalog: Catalog = {
  name: 'Electricistas',
  keywords: ['Electricistas', 'Domicilio', 'Bogotá'],
  categories: [
    {
      name: 'Diseños',
      services: [
        {
          name: 'Diseños de instalaciones eléctricas',
          description:
            'El diseño eléctrico es el proceso de planificación y creación de equipos eléctricos, tales como componentes eléctricos, esquemas, equipos de iluminación, sistemas de energía e infraestructura de telecomunicaciones.',
          image: 'instalacionesElectricas',
        },
        {
          name: 'Diseño de sistema de puesta a tierra',
          description:
            'El objetivo es evitar los posibles daños a equipos por sobretensiones y optimizar la eficiencia de las protecciones eléctricas, al facilitar una oportuna conducción de la corriente de falla a tierra.',
          image: 'sistemaPuestaTierra',
        },
        {
          name: 'Diseño de sistemas de apantallamiento',
          description:
            'El apantallamiento es un método de protección contra descargas eléctricas atmosféricas, se construye como una red de distribución de energía que termina por conducir a la tierra las descargas atmosféricas.',
          image: 'sistemasApantallamiento',
        },
      ],
    },
    {
      name: 'Construcción',
      services: [
        {
          name: 'Montaje de subestaciones eléctricas',
          description:
            'Las subestaciones eléctricas son activos eléctricos que le permiten a un edificio o planta de producción, disponer de una potencia instalada para su gestión y uso dedicado.',
          image: 'subestacionesElectricas',
        },
        {
          name: 'Instalación de tableros eléctricos',
          description:
            'Es uno de los componentes principales de una instalación eléctrica, en él se protegen cada uno de los distintos circuitos en los que se divide la instalación a través de fusibles, protecciones magnetotérmicas y diferenciales de una construcción.',
          image: 'tablerosElectricos',
        },
        {
          name: 'Realizamos reparaciones eléctricas',
          description:
            '1. Cortocircuito. Se trata de un fallo en un aparato conectado a la corriente o en la línea eléctrica. Se produce por una diferencia de potencial.  2. Sobrecarga de tensión. Ocurre cuando la tensión que fluye por el circuito es superior a la que debería, lo que puede afectar al estado de la instalación eléctrica.',
          image: 'reparacionesElectricas',
        },
        {
          name: 'Reparación e instalación de tomacorrientes normales',
          description:
            'Es un elemento de la instalación que dispone de ranuras para la inserción de las clavijas externas, llamadas “clavija macho”, que se encuentran en el extremo del cable. Estas conexiones son la boca de la electricidad y permiten la utilización de la energía.',
          image: 'tomacorrientesNormales',
        },
        {
          name: 'Reparación e instalación de tomas seguridad o con polarización',
          description:
            'Mecanismo de seguridad usado para evitar descargas eléctricas si tocas una parte energizada de algún aparato conectado a una red eléctrica. Vienen en dos formas: tomacorriente polarizado con dos puntas de diferentes tamaños y tomacorriente polarizado con dos puntas planas y una tercera punta circular debajo.',
          image: 'tomasPolarizacion',
        },
      ],
    },
    {
      name: 'Paneles solares',
      services: [
        {
          name: 'Diseños de paneles solares',
          description:
            'Un panel fotovoltaico es un tipo de panel solar diseñado para el aprovechamiento de la energía solar fotovoltaica. Su función es transformar la energía solar en electricidad. También llamada como módulo fotovoltaico.',
          image: 'disenosPanelesSolares',
        },
        {
          name: 'Instalación de Paneles Solares',
          description:
            'Se realiza el montaje de una instalación fotovoltaica que está compuesta por módulos (paneles) fotovoltaicos, por un componente eléctrico (cableado) y por una electrónica (inversores). Los módulos aprovechan la energía solar incidente para producir energía eléctrica a través del efecto fotovoltaico.',
          image: 'instalacionPanelesSolares',
        },
        {
          name: 'Paneles Solares autonómos con baterías',
          description:
            'Se utilizan especialmente en zonas rurales y remotas donde no existe una red eléctrica que abastezca de energía el sector.',
          image: 'instalacionPanelesSolares',
        },
        {
          name: 'Paneles Solares interconectados a la red eléctrica',
          description:
            'Se utilizan en viviendas, comercio e industria donde se requiere reducir el consumo de energía del operador de red.',
          image: 'instalacionPanelesSolares',
        },
        {
          name: 'Paneles Solares combinados con sistemas fotovoltaicos',
          description:
            'Se pueden utilizar para reducir el uso de energía, para disponer de una fuente de energía alterna y utilizarla como respaldo ó programando el consumo de energía.',
          image: 'instalacionPanelesSolares',
        },
      ],
    },
    {
      name: 'Cámaras de seguridad',
      services: [
        {
          name: 'Instalación de cámaras de seguridad para interiores',
          description:
            'Son las más sencillas que podemos encontrar en el mercado y las más baratas dado que no necesitan tantos mecanismos y protecciones como las que se utilizan en el exterior.',
          image: 'camarasInteriores',
        },
        {
          name: 'Instalación de cámaras de seguridad para exteriores',
          description:
            'La vigilancia exterior de cualquier instalación es el primer muro de seguridad, y para este tipo de servicio ofrecemos las cámaras térmicas, cámaras convencionales, y cámaras Domo PTZ, que son idóneas para este tipo de trabajo.',
          image: 'camarasExteriores',
        },
        {
          name: 'Instalación de cámaras robotizadas',
          description:
            'Ofrecen gran precisión de movimiento y un potente zoom óptico (hasta 30x). Las distintas resoluciones (PAL, 720p, 1080i, 1080p, 4K) de las cámaras cubren las necesidades de una amplia gama de servicios en: videoconferencia, proyectos de Broadcast, retransmisión de eventos/conferencias. Todo ello con la garantía de calidad de Lumens, Vaddio y Konftel.',
          image: 'camarasRobotizadas',
        },
        {
          name: 'Instalación de cámaras fijas',
          description:
            'Se emplean para vigilancia de puntos críticos de la operación, como son los accesos peatonales y vehiculares, bóvedas, almacenes y bodegas, parqueaderos y otras zonas de gran importancia.',
          image: 'camarasFijas',
        },
        {
          name: 'Instalación de cámaras panorámicas',
          description:
            'Las cámaras panorámicas permiten cubrir grandes perímetros con solo una cámara. Pueden emplearse para supervisar actividades y detectar incidentes en espacios de grandes dimensiones, controlar el tránsito de personas y mejorar la gestión de áreas.',
          image: 'camarasPanoramicas',
        },
        {
          name: 'Instalación de cámaras Domo',
          description:
            'Tienen un diseño ovalado, conformado por un cuerpo circular que contiene la lente y el sistema en su interior, por fuera tiene una cubierta de vidrio, estas cámaras son para techo y solo algunos modelos permiten ser montadas en pared.',
          image: 'camarasDomo',
        },
        {
          name: 'Instalación de circuito cerrado de TV',
          description:
            'Es una instalación de diversos componentes conectados entre sí que una vez funcionan, crean circuitos de imágenes que nadie podrá ver fuera del mismo circuito. Por ello se diferencian claramente de las señales de televisión habituales, ya que en este caso la señal no puede ser vista a través de antena o equipo alguno que no pertenezca al propio circuito.',
          image: 'cctv',
        },
        {
          name: 'Instalación de cámaras IP',
          description:
            'Son aquellas que se conectan directamente a Internet mostrando la imagen que visualiza. Son las más utilizadas y vendidas del mercado dado que actualmente además incorporan wifi y puedes manejarlas y ver las imágenes que captan desde tu ordenador, smartphone o tablet.',
          image: 'camarasIP',
        },
        {
          name: 'Instalación de alarmas',
          description:
            'Venta e instalación de paneles de alarma, baterías, fuentes de poder para alarmas, sirenas, sensores pesados y livianos para puerta, sensores de movimiento normales y antimascota, de 3 rayos, y de ruptura de ventana.',
          image: 'instalacionAlarmas',
        },
      ],
    },
    {
      name: 'Sistema de aire acondicionado',
      services: [
        {
          name: 'Montaje de cavas de refrigeración o cuartos fríos',
          description:
            'La instalación de nuestros cuartos fríos destacamos la ingeniería aplicada, fabricando cavas refrigeradas teniendo en las necesidades de cada artículo, asegurando la eficiencia de las mismas desde su diseño, logrando así, desarrollar proyectos amigables con el medio ambiente y un buen funcionamiento.',
          image: 'montajeCavas',
        },
        {
          name: 'Montaje de aire acondicionado vehicular a gas o eléctrico',
          description:
            'El aire acondicionado automotriz es un sistema que se encarga de enfriar, purificar y filtrar el aire dentro de los automóviles.',
          image: 'montajeAireVehicular',
        },
        {
          name: 'Montaje de aire acondicionado residencial a gas o eléctrico',
          description:
            'Manejamos varios tipos de equipos de aire acondicionado como: Split muro, Split piso cielo, Split Cassette, Split ductos, Ventana, Portátil, Compactos. Para los domésticos estos climatizan hasta 60 metros cuadrados por lo tanto su uso se concentra en Casas, Oficinas, comercios, etc.',
          image: 'montajeAireResidencial',
        },
        {
          name: 'Mantenimiento y reparaciones de neveras, y lavadoras',
          description:
            'Nuestro servicio técnico resuelve el daño que presenta su electrodoméstico en las diferentes marcas como; LG, Samsung, Mabe, Whirlpool, también revisamos las partes y accesorios que lo componen, para evitar futuros inconvenientes, asegurando su correcto funcionamiento.',
          image: 'mantenimientoLavadoras',
        },
      ],
    },
    //{
    //  name: 'Asesorías',
    //  services: [
    //    {
    //      name: 'Medición de parámetros eléctricos',
    //      description:
    //        'Realizamos el servicio o consultoría con las siguientes herramientas; multímetros, pinzas amperimétricas, detectores de corriente o tensión, que miden los parámetros eléctricos tales como corriente, tensión o resistencia de forma segura y precisa.',
    //      image: 'parametrosElectricos',
    //    },
    //    {
    //      name: 'Estudios de ingeniería',
    //      description: '',
    //      image: 'estudiosIngenieria',
    //    },
    //  ],
    //},
  ],
}

export const getCatalog = (imageSources: Record<string, any>) => {
  return getBaseCatalog(catalog, imageSources, '/electricistas/')
}
