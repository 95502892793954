import * as React from 'react'
import { PageProps, Link } from 'gatsby'
import lunr from 'lunr'
// @ts-ignore
import esLang from 'lunr-languages/lunr.es'
// @ts-ignore
import stemmer from 'lunr-languages/lunr.stemmer.support'
import { debounce } from 'debounce'
import { FaSearch } from 'react-icons/fa'
import { Layout } from '../components/default-layout'
import { SEO } from '../components/seo'
import { ProductsWrapper } from '../components/products'
import { ProductsList } from '../components/catalog'
import { DefaultLogo } from '../components/logo'
import { getCatalog as plumberCatalog } from '../data/plumber-catalog'
import { getCatalog as locksmithCatalog } from '../data/locksmith-catalog'
import { getCatalog as electricianCatalog } from '../data/electrician-catalog'
import { Service } from '../components/types'
import config from '../components/config'
import { useImages } from '../components/image'
import { DefaultContainer } from '../components/containers'

stemmer(lunr)
esLang(lunr)

export const Head: React.FC = () => {
  return (
    <SEO
      article={false}
      title={'Busqueda de servicios'}
      description={
        'Busca los servicios de los cuales disponemos en Domicilios 24/7 en todo Bogotá, podrás encontrar servicios en cerrajería, plomería y electriciastas.'
      }
    />
  )
}

const useSearch = () => {
  const images = useImages()
  const [index, setIndex] = React.useState<{ search: (i: string) => any[] }>()
  const [documents, setDocuments] = React.useState<Record<string, any>>({})

  React.useEffect(() => {
    if (index) {
      return
    }

    const documentsMap: Record<string, any> = {}
    const searchIndex = lunr(function () {
      // @ts-ignore
      this.use(lunr.es)
      this.field('keywords')
      this.field('category')
      this.field('name')
      this.field('description')
      this.ref('id')

      const catalogs = [
        plumberCatalog(images),
        locksmithCatalog(images),
        electricianCatalog(images),
      ]

      catalogs.forEach((catalog, index) => {
        catalog.categories.forEach((category, kindex) => {
          category.services.forEach((service, jindex) => {
            const id = `${index}:${kindex}:${jindex}`
            const doc = {
              ...service,
              id,
              category: category.name,
              url: category.url,
              keywords: catalog.keywords?.join(', ') || '',
            }
            this.add(doc)
            documentsMap[id] = doc
          })
        })
      })
    })

    setDocuments(documentsMap)
    setIndex(searchIndex)
  }, [images, index])

  const search = React.useCallback(
    async (input: string) => {
      if (!index) {
        return []
      }

      // Remove special characters from the search input
      input = input.replace(/([*~\\^+-])/, '\\$1')

      const tmpMap = {}
      const scores = [
        ...(await index?.search(input + '*')),
        ...(await index?.search(input)),
      ]
      const sortedScores = await scores.sort((a, b) => b.score - a.score)
      await sortedScores.forEach(score => {
        // @ts-ignore
        if (!tmpMap[score.ref] || tmpMap[score.ref].score < score.ref) {
          // @ts-ignore
          tmpMap[score.ref] = score
        }
      })

      return Object.keys(tmpMap).map(key => ({
        // @ts-ignore
        ...tmpMap[key],
        ...documents[key],
      }))
    },
    [documents, index],
  )

  return {
    index,
    search,
    available: !!index,
  }
}

type UseDebouncedSearchOptions = [
  (i: string, cb?: (i: string) => void) => void,
  { loading: boolean; data: Service[]; ready: boolean },
]

const useDebouncedSearch = (time: number): UseDebouncedSearchOptions => {
  const [data, setData] = React.useState<Service[]>([])
  const [loading, setLoading] = React.useState(false)
  const { search, available } = useSearch()
  const searchDebounced = React.useMemo(() => {
    const cb = debounce(async (i: string, cb?: (i: string) => void) => {
      setData(await search(i))
      setLoading(false)
      cb?.(i)
    }, time)

    return (i: string) => {
      setLoading(true)
      cb(i)
    }
  }, [search, time])

  return [
    searchDebounced,
    { loading: !available || loading, data, ready: available },
  ]
}

const Search: React.FC<PageProps> = ({ location, navigate }) => {
  const [urlSearch] = React.useState(() => new URLSearchParams(location.search))
  const [find, { loading, data: results, ready }] = useDebouncedSearch(1000)
  const [search] = React.useState(() => urlSearch.get('q') || '')

  React.useEffect(() => {
    if (ready) {
      const query = urlSearch.get('q') || ''
      find(query)
    }
  }, [find, ready, urlSearch])

  return (
    <Layout theme={'blue'} visible={{}} logo={<DefaultLogo />}>
      <DefaultContainer>
        <div className={'text-center py-6'}>
          <h1 className={'text-3xl mb-4'}>Busqueda de servicios</h1>
          <p className={'mb-15'}>
            Busca los productos por nombre, marca, descripción o referencia.
          </p>
        </div>

        <DelayedInput
          initialValue={search}
          onChange={e => {
            // TODO Update the URL while creating a new search.
            // @ts-ignore
            find(e.target.value)
          }}
        />

        {loading && (
          <div
            className={'w-full bg-gray-400 my-6 rounded'}
            style={{ height: '50vh' }}
          />
        )}

        {!loading && (
          <div
            className={
              'grid my-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9'
            }
          >
            <ProductsWrapper>
              <ProductsList
                light
                products={results}
                category={{ name: 'Busqueda', services: [] }}
                render={item => (
                  <div className={'text-center my-4'}>
                    <Link
                      to={item.url || config.main.url}
                      className={'btn btn-blue'}
                    >
                      Ver más
                    </Link>
                  </div>
                )}
              />
            </ProductsWrapper>
          </div>
        )}
      </DefaultContainer>
    </Layout>
  )
}

const DelayedInput = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & { initialValue?: string }
>(({ onChange, initialValue, ...props }, ref) => {
  const [value, setValue] = React.useState(initialValue)
  const debouncedChange = React.useMemo(
    () => debounce((ev: any) => onChange?.(ev), 1000),
    [onChange],
  )

  return (
    <div
      className={'input flex items-center'}
      style={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <FaSearch />
      <input
        autoFocus
        autoComplete={'off'}
        aria-label={'Buscar'}
        placeholder={'Apertura de vehículos...'}
        className={'appearance-none ml-4 w-full py-4 outline-none'}
        value={value}
        onChange={e => {
          setValue(e.target.value)
          debouncedChange(e)
        }}
        {...props}
      />
    </div>
  )
})

export default Search
